import { SET_STAGE, SET_DRAW_STAGE, SET_TITLE } from './types';

/* State Shape
{
  stage: integer,
  drawStage: integer
}
*/

const initialState = {
  stage: 0,
  drawStage: 0,
  title: 'Ticket Ordering'
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_STAGE:
      return { ...state, stage: payload };
    case SET_DRAW_STAGE:
      return { ...state, drawStage: payload };
    case SET_TITLE:
      return { ...state, title: payload };
    default:
      return state;
  }
};

export default reducer;
