import { TOGGLE_ORDER_MODAL,
  TOGGLE_EMPTY_CART_MODAL,
  TOGGLE_REQUIRE_TICKET_MODAL,
  TOGGLE_CONTACT_MODAL,
  SET_CONTACT_MODAL_CONTENT} from './types';

export const toggleOrderModal = () => ({ type: TOGGLE_ORDER_MODAL });
export const toggleEmptyCartModal = () => ({ type: TOGGLE_EMPTY_CART_MODAL });
export const toggleRequireTicketModal = () => ({ type: TOGGLE_REQUIRE_TICKET_MODAL });
export const toggleContactModal = () => ({ type: TOGGLE_CONTACT_MODAL });
export const setContactModalContent = content => ({ 
  type: SET_CONTACT_MODAL_CONTENT, 
  payload: content 
});
