import loadingState from './loading';
import modalState from './modals';
import orderingState from './ordering';
import stageState from './stage';
import errorState from './error';
import credentialsState from './credentials';
import menuState from './menu';

export default {
  loadingState,
  modalState,
  orderingState,
  stageState,
  errorState,
  credentialsState,
  menuState,
};
