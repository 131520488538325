import { SET_ERROR } from './types';

/* State Shape
{
  error: string
}
*/

const initialState = {
  error: '',
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ERROR:
      return { ...state, error: payload };
    default:
      return state;
  }
};

export default reducer;
