import { fromJS, List, Map } from 'immutable';

import { attribType } from 'utils/attributes';

// TODO: move to consts file
const ticketStatus = {
  SOLD_OUT: 'S',
  AVAILABLE: 'A',
  WAITLISTED: 'W'
};

export const isMaxPack = pack => pack && pack.get('code') === 'MaxPack';

export const isNotMaxPack = pack => pack && pack.get('code') !== 'MaxPack';

export const isSoldOut = pack => pack && pack.get('status') === ticketStatus.SOLD_OUT;

export const isNotSoldOut = pack => pack && pack.get('status') !== ticketStatus.SOLD_OUT;

export const isAvailable = pack => pack && pack.get('status') === ticketStatus.AVAILABLE;

export const isWaitlisted = pack => pack && pack.get('status') === ticketStatus.WAITLISTED;

export const isMonoPack = pack => pack && pack.get('packDrawTypes').size === 1;

export const isOrdered = pack => pack && pack.get('ordered') > 0;

// getQuantity from mono packs
const FIRST_ITEM = 0;
export const getQuantity = pack => pack.getIn(['packDrawTypes', FIRST_ITEM, 'qty']);
export const getDrawCode = pack => pack.getIn(['packDrawTypes', FIRST_ITEM, 'code']);
export const getDesc = pack => pack.getIn(['packDrawTypes', FIRST_ITEM, 'desc']);

export const getDrawByPack = (pack, draws) =>
  draws.find(draw => pack.get('code') === draw.get('code'));

export const filterTicketsByDrawId = (pack, id) =>
  pack.update('packDrawTypes', tickets => tickets.filter(ticket => ticket.get('code') === id));

// check if pack is not a bundle and has tickets for a specific draw
export const hasTicketsForSingleDraw = (pack, drawId) =>
  isMonoPack(pack) && getDrawCode(pack) === drawId;

// return packs that have at least one ticket for a specified draw
export const getPacksByDraw = (draw, packs) => {
  const drawId = draw.get('code');

  return packs
    .map(pack => filterTicketsByDrawId(pack, drawId))
    .filter(pack => pack.get('packDrawTypes').size > 0);
};

export const getPacksByDrawId = (drawId, packs) =>
  packs.filter(
    pack =>
      isMaxPack(pack) || pack.get('packDrawTypes').find(ticket => ticket.get('code') === drawId)
  );

export const getPacksExcludingDrawId = (drawId, packs) =>
  packs.filter(pack => pack.get('packDrawTypes').find(ticket => ticket.get('code') !== drawId));

export const setOrderedTickets = packs =>
  packs.map(pack =>
    pack.update('packDrawTypes', tickets =>
      tickets.map(ticket => ticket.set('ordered', ticket.get('qty') * pack.get('ordered')))
    )
  );

export const getTotalTickets = packs => {
  if (!packs) return undefined;

  const totalTicketsPerPack = setOrderedTickets(packs).map(pack =>
    pack.get('packDrawTypes').reduce((a, ticket) => a + ticket.get('ordered'), 0)
  );
  const totalTickets = totalTicketsPerPack.reduce((a, b) => a + b, 0);

  const result = totalTickets;
  return result;
};

export const getTotalTicketsByDrawId = (packs, id) => {
  if (!packs) return undefined;

  const validPacks = getPacksByDrawId(id, packs);
  const packsWithOrders = setOrderedTickets(validPacks);

  return getTotalTickets(packsWithOrders);
};

// replace order for a draw with a new order
export const setOrderContentsForDraw = (newOrder, sourceOrder, drawId) => {
  if (!drawId) {
    return sourceOrder;
  }

  const newOrderHash = newOrder.groupBy(pack => pack.get('code')).map(pack => pack.first());

  const reset = sourceOrder
    .map(pack => (hasTicketsForSingleDraw(pack, drawId) ? pack.set('ordered', 0) : pack))
    .map(pack => newOrderHash.get(pack.get('code')) || pack);

  return reset;
};

export const getMonoPacks = packs => packs.filter(pack => pack.get('packDrawTypes').size === 1);

export const getBundlePacks = packs => packs.filter(pack => pack.get('packDrawTypes').size > 1);

export const getMonoPacksByDrawId = (id, packs) =>
  packs
    .filter(pack => pack.get('packDrawTypes').find(ticket => ticket.get('code') === id))
    .filter(pack => pack.get('packDrawTypes').size === 1);

export const displayDrawQuantities = packs =>
  packs
    .filter(isOrdered)
    .map(pack => `${pack.get('ordered')}x ${pack.get('desc')}`)
    .join(', ');

export const orderToDisplay = packsByDraw => {
  const result = packsByDraw.map(draw =>
    draw.set('totalTickets', getTotalTickets(draw.get('packs')))
  );

  return result;
};

export const getOrderedPacks = (packs, mono = false) => {
  const filteredPacks = packs.filter(isOrdered);

  return mono ? getMonoPacks(filteredPacks) : filteredPacks;
};

export const getBundleOrders = packs => {
  const bundles = getOrderedPacks(getBundlePacks(packs));
  const orderedBundles = setOrderedTickets(bundles);
  return orderedBundles;
};

export const getOrder = (packs, draws) => {
  const orderedPacks = getOrderedPacks(packs, true);

  // group ordered tickets by draw and remove draws without tickets
  const packsByDraw = draws
    .map(draw => draw.set('packs', getPacksByDraw(draw, orderedPacks)))
    .filter(draw => draw.get('packs').size > 0);

  return orderToDisplay(packsByDraw);
};

const accumulatePrice = (total, pack) => total + pack.get('ordered') * pack.get('price');

export const getTotalPrice = packs => packs.reduce(accumulatePrice, 0);

export const getTotalPriceAvailable = packs => packs.filter(isAvailable).reduce(accumulatePrice, 0);

export const getTotalPriceWaitlisted = packs =>
  packs.filter(isWaitlisted).reduce(accumulatePrice, 0);

const getNextLargestPack = (index, allPacks) => {
  const pack = allPacks.get(index);
  const packSize = getQuantity(pack);
  let searchIndex = index + 1;
  let result;

  for (searchIndex; searchIndex < allPacks.size; searchIndex += 1) {
    const nextPack = allPacks.get(searchIndex);
    // if next pack is undefined, return
    if (!nextPack) break;

    // if next pack exists and is larger, return pack
    if (nextPack && getQuantity(nextPack) > packSize) {
      result = nextPack;
      break;
    }
  }
  return result;
};

// generates a list of packs and thresholds for upgrading
export const orderUpgradeMap = packs => {
  // sorting based on first ticket as we're working with mono packs
  const groupedPacks = getMonoPacks(packs)
    .filter(isNotSoldOut)
    .groupBy(item =>
      item
        .get('packDrawTypes')
        .first()
        .get('code')
    );

  const sortedByQuantity = groupedPacks.map(draw => draw.sortBy(getQuantity));
  const result = [];
  const quantity = ['packDrawTypes', FIRST_ITEM, 'qty'];

  sortedByQuantity.forEach(draw =>
    draw.forEach((pack, index) => {
      const largerPack = getNextLargestPack(index, draw);
      if (largerPack) {
        result.push({
          packId: pack.get('code'),
          nextId: largerPack.get('code'),
          quantity: pack.getIn(quantity),
          threshold: largerPack.getIn(quantity),
          drawId: pack.getIn(['packDrawTypes', FIRST_ITEM, 'code'])
        });
      }
    })
  );

  return fromJS(result);
};

export const getTicketsOrdered = pack =>
  pack.getIn(['packDrawTypes', FIRST_ITEM, 'qty']) * pack.get('ordered');

export const optimizeOrderForPacks = (packs, cost) => {
  // set price per ticket on each pack
  const pptPacks = packs.map(pack => {
    const quantity = getQuantity(pack);
    const price = pack.get('price');
    const pricePerTicket = price / quantity;
    return pack.set('pricePerTicket', pricePerTicket);
  });

  // order them by best price per ticket
  const sortedPacks = pptPacks.sortBy(pack => pack.get('pricePerTicket'));

  // generate a set of order changes based on available price
  let costRemaining = cost;
  const newOrder = [];
  sortedPacks.forEach(pack => {
    const numPacks = Math.floor(costRemaining / pack.get('price'));
    costRemaining %= pack.get('price');
    if (numPacks) {
      newOrder.push(pack.set('ordered', numPacks));
    }
  });

  return fromJS(newOrder);
};

export const generateMaxPack = packs => {
  // get total number of draws
  const numDraws = packs.groupBy(getDrawCode).size;

  // get all non-soldout packs with tickets for a single draw
  const validPacks = packs.filter(isMonoPack).filter(isNotSoldOut);

  // sort packs by number of tickets, group by draw, and keep only the largest pack
  // ie. grand prize, 50 50, cash calendar
  const packsByDraw = validPacks
    .sortBy(getQuantity)
    .groupBy(getDrawCode)
    .map(draw => draw.last())
    .toList();

  // if the maxPack does not contain tickets for all draws, set it to sold out
  const status = numDraws === packsByDraw.size ? 'A' : 'S';

  const price =
    status === 'S' ? undefined : packsByDraw.reduce((total, pack) => total + pack.get('price'), 0);

  const result = fromJS({
    code: 'MaxPack',
    desc: 'Max Pack',
    ordered: 0,
    packDrawTypes: packsByDraw.map(pack => pack.getIn(['packDrawTypes', 0])),
    packs: packsByDraw,
    isMaxPack: true,
    price,
    status
  });

  return result;
};

export const extractContentsText = pack => {
  if (isMaxPack(pack)) {
    return pack.get('packDrawTypes').map(ticket => ticket.get('desc'));
  }

  if (!isMonoPack(pack)) {
    return pack.get('packDrawTypes').map(ticket => `${ticket.get('qty')}x ${ticket.get('desc')}`);
  }

  return undefined;
};

export const extractMaxPackText = maxPack => maxPack.get('packs').map(pack => pack.get('desc'));

// Transform purchaser form data to soala order
export const transformPurchaserInfo = purchaserInfo => {
  // TODO: yup
  // no honorific, country, cell number, work number, address 2 input right now
  const pi = purchaserInfo;
  const names = pi.ticketNames.map(name => `${name.first_name} ${name.last_name}`);
  const result = {
    hon: pi.honorific,
    first: pi.firstName,
    last: pi.lastName,
    address: {
      addr1: pi.address,
      addr2: pi.address2,
      city: pi.city,
      state: pi.province,
      country: '',
      postal: pi.postalCode,
      email: pi.email,
      home: pi.homePhone,
      work: pi.workPhone,
      cell: pi.mobilePhone,
      ext: pi.ext
    },
    names
  };

  return fromJS(result);
};

// TODO: we currently only support one gateway
// this function should eventually create a payment object that includes the amounts
const getGateway = gateways => gateways[0].code;

const extractAttributes = (purchaserInfo, attributes) => {
  const result = [];

  // SOALA specifies return values for true/false boolean attributes
  const booleanAttributes = attributes.filter(
    attr =>
      attr.get('trueValue') || attr.get('falseValue') || attr.get('datatype') === attribType.BOOL
  );

  const keys = Object.keys(purchaserInfo).filter(key => key.includes('attrib'));
  keys.forEach(key => {
    const code = key.replace(/[^0-9]/g, '');
    const booleanAttribute = booleanAttributes.find(item => `${item.get('code')}` === code);
    let value = purchaserInfo[key];

    // if a false return value is not specified, default to empty string
    if (booleanAttribute) {
      // remap return values to override values
      if (value) {
        value = booleanAttribute.get('trueValue');
      } else {
        value = '';
      }
    }

    // specifically exclude undefined values
    if (value !== undefined) {
      result.push({ code, value });
    }
  });

  return result;
};

// Create confirm order object for SOALA
export const createConfirmOrder = ({
  purchaserInfo,
  jwt,
  order,
  gateways,
  transactionId,
  attributes
}) => {
  const maxPackContents = order.filter(isMaxPack).getIn([FIRST_ITEM, 'packs']) || new List();
  const maxPackQuantity = order.filter(isMaxPack).getIn([FIRST_ITEM, 'ordered']) || 0;

  const updateWithMaxPacks = pack => {
    const isPartOfMaxPack =
      !!maxPackContents.find(p => p.get('code') === pack.get('code')) && maxPackQuantity > 0;

    return isPartOfMaxPack ? pack.update('ordered', qty => qty + maxPackQuantity) : pack;
  };

  const orderWithMaxPacks = order
    .filter(isNotMaxPack)
    .map(updateWithMaxPacks)
    .filter(isOrdered);

  const purchases = orderWithMaxPacks.map(
    pack =>
      new Map({
        code: pack.get('code'),
        qty: pack.get('ordered'),
        wait: isWaitlisted(pack)
      })
  );

  const purchaser = transformPurchaserInfo(purchaserInfo);

  const attribs = extractAttributes(purchaserInfo, attributes);

  // TODO: post-mvp allow for multiple payment codes
  const payments = fromJS([
    {
      amt: getTotalPriceAvailable(orderWithMaxPacks),
      gateway: getGateway(gateways),
      code: 1,
      amtOnHold: getTotalPriceWaitlisted(orderWithMaxPacks)
    }
  ]);

  const result = purchaser
    .set('purchases', purchases)
    .set('payments', payments)
    .set('subs', new List())
    .set('charges', new List())
    .set('holders', new List())
    .set('jwt', jwt)
    .set('trxId', transactionId)
    .set('source', 'WEB')
    .set('loc', 'WEB')
    .set('hardcopy', false)
    .set('attribs', attribs);

  return result;
};

export const hasOrderedWaitlistedPacks = order =>
  !!order.filter(isOrdered).filter(isWaitlisted).size;

// Takes various form objects and constructs an order object for SOALA
export const createSoalaPayment = ({
  order,
  purchaser,
  jwt,
  nonce,
  descriptor,
  verifiedOrder,
  maskedPan,
  expMm,
  expYy,
  orderStatus = 'O'
}) => {
  const {
    firstName,
    lastName,
    address,
    city,
    province,
    postalCode,
    email,
    homePhone,
    mobilePhone
  } = purchaser;

  const addrObject = {
    addr1: address,
    addr2: '',
    city,
    state: province,
    postal_code: postalCode,
    country: 'CA',
    email,
    home: homePhone,
    cell: mobilePhone,
    work: homePhone,
    ext: ''
  };

  // TODO: populate empty fields for other payment processors
  // ie.  auth, gwayTrxId, message
  const payments = [
    {
      code: 1,
      order: order.toJS(),
      amount: getTotalPrice(order),
      pan: maskedPan,
      expMm,
      expYy,
      auth: '',
      gwayTrxId: '',
      result: orderStatus, // set to 'C' for cancelled by user
      message: '',
      parms: [descriptor, nonce]
    }
  ];

  const result = {
    trxId: verifiedOrder.get('trxId'),
    jwt,
    source: 'WEB',
    location: 'WEB',
    honorific: '',
    first_name: firstName,
    last_name: lastName,
    address: addrObject,
    attributes: [],
    subscriptions: [],
    payments,
    holders: []
  };

  return result;
};
