import reducer from './reducers';

import * as credentialsOperations from './operations';
import * as credentialsTypes from './types';

export {
  credentialsOperations,
  credentialsTypes,
};

export default reducer;
