import { TOGGLE_ORDER_MODAL,
  TOGGLE_EMPTY_CART_MODAL,
  TOGGLE_REQUIRE_TICKET_MODAL,
  TOGGLE_CONTACT_MODAL,
  SET_CONTACT_MODAL_CONTENT } from './types';

/* State Shape
{
  displayOrderModal: boolean,
  displayEmptyCartModal: boolean,
  displayRequireTicketModal: boolean,
  displayContactModal: boolean,
  contactModalContent: string,
}
*/

const initialState = {
  displayOrderModal: false,
  displayEmptyCartModal: false,
  displayRequireTicketModal: false,
  displayContactModal: false,
  contactModalContent: 'DEFAULT',
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_ORDER_MODAL:
      return { ...state, displayOrderModal: !state.displayOrderModal };
    case TOGGLE_EMPTY_CART_MODAL:
      return { ...state, displayEmptyCartModal: !state.displayEmptyCartModal };
    case TOGGLE_REQUIRE_TICKET_MODAL:
      return { ...state, displayRequireTicketModal: !state.displayRequireTicketModal };
    case TOGGLE_CONTACT_MODAL:
      return { ...state, displayContactModal: !state.displayContactModal };
    case SET_CONTACT_MODAL_CONTENT:
      return { ...state, contactModalContent: payload };
    default:
      return state;
  }
};

export default reducer;
