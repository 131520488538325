import { TICKET_PURCHASE,
  PURCHASER_FORM,
  CONFIRM_ORDER,
  IP_CHECK,
  pageOrder } from 'constants/pages';

export const getFeaturedCategoryPrizes = (categoryPrizePages) => {
  let featuredCategoryPrizes = [];
  let featuredItems = [];

  if (categoryPrizePages) {
    categoryPrizePages.forEach((categoryPrizePage) => {
      if (categoryPrizePage.categoryBlocks) {
        featuredItems = categoryPrizePage.categoryBlocks
          .filter(block => block.blockType === 'prize_block').slice(0, 3)
          .map((_featuredItem) => {
            // to avoid [no-param-reassign] error decouple instance
            const featuredItem = Object.assign({}, _featuredItem);
            // assign a property
            featuredItem.pageUrl = categoryPrizePage.pageName.toLowerCase().replace(/\s/g, '-');
            // return new instance
            return featuredItem;
          });
      }

      featuredCategoryPrizes = [...featuredCategoryPrizes, ...featuredItems];
    });
  }

  return featuredCategoryPrizes;
};

export const isPhoneNumber = (value) => {
  if (!value) { return true; }
  const extractNumbers = (value && value.match(/\d+/g)) || [];
  return extractNumbers.join('').length === 10;
};

// denormalizes two immutable lists by column name
export const denormalize = (a, aCode, b, bCode) => {
  const hashMap = b.groupBy(item => item.get(bCode || aCode)).map(item => item.first());
  const result = a.map((item) => {
    const joinId = item.get(aCode);
    const lookupValue = hashMap.get(joinId);
    return item.merge(lookupValue);
  });

  return result;
};

// Rebuild this function completely
// lookup address in object by string
export const byString = (o, s) => {
  if (!o || !s) return undefined;

  let object = o;
  const address = s
    .replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
    .replace(/^\./, '') // strip a leading dot
    .split('.'); // convert to array
  for (let i = 0, n = address.length; i < n; ++i) {
    const key = address[i];
    // Sometimes Yup does not properly clear out errors in FieldArray validation, leaving an undefined
    // TODO: fix error in Yup, or filter objects?
    if (!object) return undefined;
    if (key in object) {
      object = object[key];
    } else {
      return undefined;
    }
  }
  return object;
};

export const scrollToTop = () => {
  try {
    window.scroll({
      behavior: 'smooth',
      top: 0,
    });
  } catch (err) {
    if (err instanceof TypeError) {
      window.scroll(0, 0);
    } else {
      throw err;
    }
  }
};

export const formatPhoneNumber = phoneNumber => phoneNumber.replace(
  /(\d{3})(\d{3})(\d{4})/,
  '($1)$2-$3',
);

export const setTitle = (draws, stage, drawStage) => {
  switch (pageOrder[stage]) {
    case IP_CHECK:
      return 'Region Check';
    case TICKET_PURCHASE:
      return draws.getIn([drawStage, 'desc']);
    case PURCHASER_FORM:
      return 'Purchaser Information';
    case CONFIRM_ORDER:
      return 'Your Order Summary';
    default:
      return 'Thank You!';
  }
};

export const generateBestDealText = (bonusTickets, priceSavings) => {
  const text = [];
  if (priceSavings > 0) text.push(`save $${priceSavings}`);
  if (bonusTickets > 0) text.push(`get ${bonusTickets} extra ticket${bonusTickets > 1 ? 's' : ''}`);

  if (text.length === 0) return '';

  const result = text.join(' and ');

  return `${result[0].toUpperCase()}${result.substring(1)}! `;
};

export const generateBundleDescription = tickets => tickets
  .map(ticket => `${ticket.get('ordered')}x ${ticket.get('desc')}`)
  .join(', ');

export const generateMaxPackDescription = (ordered, tickets) => tickets
  .map(ticket => `${ordered}x ${ticket.get('desc')}`)
  .join(', ');

export const camelCaseToSentence = (text) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};
