import { SET_JWT } from './types';

/* State Shape
{
  jwt: string
}
*/

const initialState = {
  jwt: ''
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_JWT:
      return { ...state, jwt: payload };
    default:
      return state;
  }
};

export default reducer;
