import reducer from './reducers';

import * as orderingOperations from './operations';
import * as orderingTypes from './types';
import * as orderingSelectors from './selectors';

export {
  orderingOperations,
  orderingTypes,
  orderingSelectors,
};

export default reducer;
