import reducer from './reducers';

import * as modalOperations from './operations';
import * as modalTypes from './types';

export {
  modalOperations,
  modalTypes,
};

export default reducer;
