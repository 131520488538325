import * as Yup from 'yup';
import { isPhoneNumber } from 'utils/helpers';

export const attribType = {
  TEXT: 'T',
  INTEGER: 'I',
  NUMBER: 'M',
  BOOL: 'B',
  LIST: 'L'
};

const postalCoderegEx = /[a-zA-Z][0-9][a-zA-Z](-| |)[0-9][a-zA-Z][0-9]/;

const getAttribName = attribute => `attrib-${attribute.code}`;

const PERMITTED_VALIDATIONS = ['length', 'decimals', 'minValue', 'maxValue', 'mandatory'];

const staticValidations = {
  firstName: Yup.string()
    .min(2, 'First Name must be at least 2 characters')
    .max(50, 'First Name must be less than 50 characters')
    .required('First Name is required!'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Last Name is required!'),
  address: Yup.string().required('Address is required!'),
  city: Yup.string().required('City is required!'),
  province: Yup.string().required('Province is required!'),
  postalCode: Yup.string()
    .matches(postalCoderegEx, 'Postal Code not valid, follow the format H8Y9K3')
    .max(7, 'Postal Code is too long')
    .required('Postal code is required!'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required!'),
  confirmEmail: Yup.string()
    .email('Invalid email')
    .oneOf([Yup.ref('email'), null], 'Emails must match')
    .required('Confirm Email is required'),
  homePhone: Yup.string()
    .required('Home phone number is required')
    .test('valid-phone', 'Home phone number must be valid', value => isPhoneNumber(value)),
  mobilePhone: Yup.string().test('valid-phone', 'Mobile phone number must be valid', value =>
    isPhoneNumber(value)
  ),
  ticketNames: Yup.array().of(
    Yup.object().shape({
      first_name: Yup.string()
        .min(2, 'First name must be at least 2 characters')
        .required('Required'), // these constraints take precedence
      last_name: Yup.string()
        .min(2, 'Last name must be at least 2 characters')
        .required('Required') // these constraints take precedence
    })
  )
};

const getAttributeDefaultValue = attribute => {
  let defaultValue = '';
  switch (attribute.datatype) {
    case 'T':
      defaultValue = '';
      break;
    case 'I':
      defaultValue = '';
      break;
    case 'N':
      defaultValue = '';
      break;
    case 'B':
      defaultValue = false;
      break;
    case 'L':
      defaultValue = '';
      break;
    default:
      defaultValue = '';
  }

  return attribute.defaultValue || defaultValue;
};

const getYupType = datatype => {
  switch (datatype) {
    case 'T':
      return Yup.string();
    case 'I':
      return Yup.number();
    case 'N':
      return Yup.number();
    case 'B':
      return Yup.boolean();
    default:
      return Yup.string();
  }
};

// recursively apply validations to yup object
const applyValidation = (yupObj, validation) => {
  if (validation.length <= 0) {
    return yupObj;
  }

  const v = validation[0];
  const remainingValidations = validation.slice(1, v.length);
  let newYupObj = yupObj;

  switch (v.type) {
    case 'length':
      newYupObj = newYupObj.max(v.value, `Maximum ${v.value} characters`);
      break;
    case 'decimals':
      break;
    case 'minValue':
      newYupObj = newYupObj.min(v.value, `Minimum value of ${v.value}`);
      break;
    case 'maxValue':
      newYupObj = newYupObj.max(v.value, `Minimum value of ${v.value}`);
      break;
    case 'mandatory':
      newYupObj = newYupObj.required('Required field');
      break;
    default:
      break;
  }

  return applyValidation(newYupObj, remainingValidations);
};

// convert each attribute to a list of KV pairs for accepted validations
const extractAttributeValidations = attribute => {
  const acceptedKeys = Object.keys(attribute).filter(k => PERMITTED_VALIDATIONS.includes(k));
  const { datatype } = attribute;

  const validations = [];
  acceptedKeys.forEach(key => {
    const item = {};
    item.type = key;
    item.value = attribute[key];
    validations.push(item);
  });

  const newYup = getYupType(datatype);

  const yupObj = applyValidation(newYup, validations);

  return yupObj;
};

export const ticketNamesEntry = (key = '') => ({
  first_name: '',
  last_name: '',
  key
});

export const getAttributeValidations = attributes => {
  const attributeValidations = {};
  attributes.forEach(a => {
    attributeValidations[getAttribName(a)] = extractAttributeValidations(a);
  });

  const result = Object.assign(staticValidations, attributeValidations);
  return result;
};

export const getAttributeInitialValues = attributes => {
  const result = {};
  attributes.forEach(attribute => {
    result[`attrib-${attribute.code}`] = getAttributeDefaultValue(attribute);
  });

  return result;
};
