import '@babel/polyfill';
import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import configureStore from './src/state/store';

const store = configureStore();

export const wrapRootElement = ({ element }) => <Provider store={store}>{element}</Provider>;

wrapRootElement.propTypes = {
  element: PropTypes.node
};

export default wrapRootElement;
