import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import reducers from './ducks';

// import all reducers from ducks/index.js

/* eslint-disable no-underscore-dangle */
const windowExist = typeof window === 'object';

// eslint-disable-next-line
const devtoolsEnhancer = windowExist ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : compose;

export default function configureStore(initialState = {}) {
  const rootReducer = combineReducers(reducers);
  return createStore(
    rootReducer,
    initialState,
    devtoolsEnhancer,
  );
}
/* eslint-enable no-underscore-dangle */
