import { TOGGLE_LOADING } from './types';

/* State Shape
{
  isLoading: boolean
}
*/

const initialState = {
  isLoading: false,
};

const reducer = (state = initialState, { type }) => {
  switch (type) {
    case TOGGLE_LOADING:
      return { ...state, isLoading: !state.isLoading };
    default:
      return state;
  }
};

export default reducer;
