export const SET_ORDER = 'SET_ORDER';
export const SET_ORDER_SUCCESS = 'SET_ORDER_SUCCESS';
export const SET_ORDER_ENDPOINT = 'SET_ORDER_ENDPOINT';
export const TOGGLE_DISPLAY_ORDER = 'TOGGLE_DISPLAY_ORDER';
export const SET_PURCHASER_INFO = 'SET_PURCHASER_INFO';
export const SET_TRANSACTION_ID = 'SET_TRANSACTION_ID';
export const SET_UPGRADE_MAP = 'SET_UPGRADE_MAP';
export const SET_PAYMENT_ENDPOINT = 'SET_PAYMENT_ENDPOINT';
export const SET_VERIFIED_ORDER = 'SET_VERIFIED_ORDER';
export const DISPLAY_CANNOT_PROGRESS = 'DISPLAY_CANNOT_PROGRESS';
