import { fromJS } from 'immutable';
import { raffle } from 'data/data.json';
import { SET_ORDER,
  SET_ORDER_SUCCESS,
  SET_ORDER_ENDPOINT,
  TOGGLE_DISPLAY_ORDER,
  SET_PURCHASER_INFO,
  SET_TRANSACTION_ID,
  SET_UPGRADE_MAP,
  SET_PAYMENT_ENDPOINT,
  SET_VERIFIED_ORDER,
  DISPLAY_CANNOT_PROGRESS } from './types';

const {
  buyFlowData: { packTypes, drawTypes, attribs },
  waitlistEnabled
} = raffle;

/* State Shape
{
  order: array,
  draws: array,
  displayOrder: boolean,
  purchaserInfo: array,
  attributes: array,
  cannotProgressDisplayed: boolean,
  orderSuccessful: boolean,
  orderEndpoint: string,
  upgradeMap: object,
  transactionId: string,
  paymentEndpoint: string,
  waitlistEnabled: boolean,
}
*/

const initialState = {
  order: fromJS(packTypes).map(pack => pack.set('ordered', 0)),
  draws: fromJS(drawTypes),
  displayOrder: true,
  attributes: fromJS(attribs),
  cannotProgressDisplayed: false,
  waitlistEnabled: waitlistEnabled || false,
  upgradeMap: {}
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ORDER:
      return { ...state, order: payload };
    case SET_ORDER_SUCCESS:
      return { ...state, orderSuccessful: payload };
    case SET_ORDER_ENDPOINT:
      return { ...state, orderEndpoint: payload };
    case TOGGLE_DISPLAY_ORDER:
      return { ...state, displayOrder: !state.displayOrder };
    case SET_PURCHASER_INFO:
      return { ...state, purchaserInfo: payload };
    case SET_TRANSACTION_ID:
      return { ...state, transactionId: payload };
    case SET_UPGRADE_MAP:
      return { ...state, upgradeMap: payload };
    case SET_PAYMENT_ENDPOINT:
      return { ...state, paymentEndpoint: payload };
    case SET_VERIFIED_ORDER:
      return { ...state, verifiedOrder: payload };
    case DISPLAY_CANNOT_PROGRESS:
      return { ...state, cannotProgressDisplayed: payload };
    default:
      return state;
  }
};

export default reducer;
